
//  THE MODEL OF A PLATE DESIGN CATEGORY, CORRESPONDING TO A PLATE DESIGN CARD


import { PlateColor } from "./PlateColor";
import { ProductModelCategory } from "./ProductModelCategory";
import { ProductModel } from "./ProductModel";
import { CurrentPlateDesign, PlateDesignModel } from "@dataModels/ProductModels";
import { PlateModel, Brand, PlateCardDesignModel } from ".";
import { BasketPlateCaptionResponseModel } from "~/dataModels/BasketModel/BasketCaptionResponseModel";
import { BasketPlateResponseModel } from "~/dataModels/BasketModel/BasketPlateResponseModel";
import { MessagePlateLocations } from "@utils/Constant";

export class ProductModelCategoryList {
  name: string
  brands?: Brand[]
  plateColors: Array<PlateColor>;
  categories: Array<ProductModelCategory>
  constructor(name: string, plateColors: Array<PlateColor>, categories: Array<ProductModelCategory>, brands?: Brand[]) {
    this.name = name;
    this.plateColors = plateColors;
    this.categories = categories;
    this.brands = brands;
  }
  add(productModelCategory: ProductModelCategory) {
    let isNewCategory = false;
    const categoryBrand = productModelCategory.brand;
    if (categoryBrand !== undefined) {
      if (this.brands === undefined) {
        this.brands = [];
      }

      if (this.brands.find(b => b.name === categoryBrand.name) === undefined) {
        this.brands.push(categoryBrand);
        isNewCategory = true;
      }

      if (productModelCategory.plateColor !== undefined) {
        if (this.plateColors.find(p => p.name === productModelCategory.plateColor.name) === undefined) {
          this.plateColors.push(productModelCategory.plateColor)
        }
      }
      if (this.categories.find(c => c.brand !== undefined && c.brand.name === categoryBrand.name && c.plateColor.name === productModelCategory.plateColor.name) === undefined) {
        isNewCategory = true;
      }
    } else {
      if (productModelCategory.plateColor !== undefined) {
        if (this.plateColors.find(p => p.name === productModelCategory.plateColor.name) === undefined) {
          this.plateColors.push(productModelCategory.plateColor)
          isNewCategory = true;
        }
      }
    }

    if (isNewCategory) {
      this.categories.push(productModelCategory);
    } else {
      const categoryBrand = productModelCategory.brand;
      if (categoryBrand === undefined) {

        let existingCategory = this.categories.find(c => c.plateColor.name === productModelCategory.plateColor.name);
        const otherCategories = this.categories.filter(c => c.plateColor.name !== productModelCategory.plateColor.name);
        if (existingCategory !== undefined) {
          existingCategory.add(productModelCategory.products)
        } else {
          existingCategory = productModelCategory
        }
        this.categories = [...otherCategories, existingCategory];
      } else {
        const newCategories: ProductModelCategory[] = [];
        this.categories.forEach(c => {
          if (c.plateColor.name === productModelCategory.plateColor.name && c.brand !== undefined && c.brand.name === categoryBrand.name) {
            c.add(productModelCategory.products)
          }
          newCategories.push(c);
        })
        this.categories = newCategories
      }
    }
  }
  getPlateCategoryByColor(colorName: string): ProductModelCategory {
    const result = this.categories.find(c => c.plateColor.name === colorName);
    if (result === undefined) {
      throw new Error("Color is not found in the products");
    } else {
      return result;
    }
  }
  getPlateCategoriesByBrand(brand: Brand): ProductModelCategory[] {
    const result = this.categories.filter(c => c.brand !== undefined && c.brand.name === brand.name);
    if (result === undefined) {
      throw new Error("Color is not found in the products");
    } else {
      return result;
    }
  }
  getPlateCategoryByPlateDesignCode(frontPlateDesignCode: number, backPlateDesignCode: number) {
    for (let category of this.categories) {
      for (let product of category.products) {
        const found = product.getAllPlateModels().find(pm => pm.plateDesignCode === frontPlateDesignCode || pm.plateDesignCode === backPlateDesignCode);
        if (found !== undefined) {
          return category;
        }
      }
    }
  }
  getCurrentProductModel(frontPlateDesignCode: number, backPlateDesignCode: number): ProductModel {
    let productModel: ProductModel | undefined = undefined;
    this.categories.forEach(c => {
      for (let product of c.products) {
        const found = product.getAllPlateModels().find(m => m.plateDesignCode === frontPlateDesignCode || m.plateDesignCode === backPlateDesignCode)
        if (found !== undefined) {
          productModel = product;
          return;
        }
      }
    })
    if (productModel !== undefined) {
      return productModel;
    } else {
      throw new Error("Failed to load current product model");
    }
  }
  getBrandPlateColors(brand: Brand): PlateColor[] {
    let result: PlateColor[] = [];
    const categories = this.getPlateCategoriesByBrand(brand);
    for (let category of categories) {
      if (result.find(r => r.name === category.plateColor.name) === undefined) {
        result.push(category.plateColor);
      }
    }
    return result;
  }
  getCurrentTextColors(frontPlateDesignCode: number, backPlateDesignCode: number) {
    const found = this.getPlateCategoryByPlateDesignCode(frontPlateDesignCode, backPlateDesignCode);
    if (found !== undefined) {
      return found.getTextColors();
    }
    return undefined;
  }
  buildPlateDesignByBrand(currentPlateDesign: CurrentPlateDesign, brandValue: string) {
    if (this.brands !== undefined) {
      const currentBrand = this.brands.find(b => b.value === brandValue);
      if (currentBrand !== undefined) {
        let plateColor: PlateColor;
        let productCategory: ProductModelCategory;
        const brandedCategories = this.getPlateCategoriesByBrand(currentBrand);
        productCategory = brandedCategories[0];
        plateColor = brandedCategories[0].plateColor

        if (productCategory !== undefined) {
          return this.buildPlateDesignModel(productCategory, plateColor, currentBrand, currentPlateDesign);
        }
      } else {
        throw new Error("The selected Brand is not found!")
      }
    }
    throw new Error("Invalid Operation: the brand you changed is not available");
  }
  buildPlateDesignModel(productCategory: ProductModelCategory, plateColor: PlateColor, brand?: Brand, currentPlateDesign?: CurrentPlateDesign) {
    const frontPlateDesign = currentPlateDesign !== undefined ? productCategory.products.find(size => size.size.id === currentPlateDesign.frontPlate.plateSize.id) ?? productCategory.products[0] : productCategory.products[0];
    const backPlateDesign = currentPlateDesign !== undefined ? productCategory.products.find(size => size.size.id === currentPlateDesign.backPlate.plateSize.id) ?? productCategory.products[0] : productCategory.products[0];
    const frontCaptionPosition = productCategory.products[0].caption;
    const frontMessageColors = productCategory.products[0].messageColors;
    if (frontPlateDesign.variants !== undefined && backPlateDesign.variants !== undefined) {
      const frontTextColor = currentPlateDesign !== undefined ? frontPlateDesign.variants?.find(textColor => textColor.textColor.id === currentPlateDesign.frontPlate.textColor.id) ?? frontPlateDesign.variants[0] : frontPlateDesign.variants[0];
      const backTextColor = currentPlateDesign !== undefined ? backPlateDesign.variants?.find(textColor => textColor.textColor.id === currentPlateDesign.backPlate.textColor.id) ?? backPlateDesign.variants[0] : frontPlateDesign.variants[0];

      return {
        brand: brand,
        plateColor: plateColor,
        frontPlate: {
          plateDesignCode: frontPlateDesign.id,
          disableDualSizing: frontPlateDesign.disableDualSizing,
          plateSize: frontPlateDesign.size,
          textColor: frontTextColor.textColor,
          captionPositon: frontCaptionPosition,
          captionMaxLength: frontPlateDesign.captionMaxLength,
          bottomCaptionMaxLength: frontPlateDesign.bottomCaptionMaxLength,
          topMessageColor: (frontMessageColors !== undefined && frontMessageColors.length > 0) ? frontMessageColors[0] : undefined,
          bottomMessageColor: (frontMessageColors !== undefined && frontMessageColors.length > 0) ? frontMessageColors[0] : undefined,
        },
        backPlate: {
          plateDesignCode: backPlateDesign.id,
          disableDualSizing: backPlateDesign.disableDualSizing,
          plateSize: backPlateDesign.size,
          textColor: backTextColor.textColor,
          captionPositon: frontCaptionPosition,
          captionMaxLength: frontPlateDesign.captionMaxLength,
          bottomCaptionMaxLength: frontPlateDesign.bottomCaptionMaxLength,
          topMessageColor: (frontMessageColors !== undefined && frontMessageColors.length > 0) ? frontMessageColors[0] : undefined,
          bottomMessageColor: (frontMessageColors !== undefined && frontMessageColors.length > 0) ? frontMessageColors[0] : undefined,
        }
      }
    }
    throw new Error(`${productCategory.products[0].name}: Invalid Product model: the product missing variant/text color.`)
  }
  buildPlateCardDesignModel(productCategory: ProductModelCategory, plateColor: PlateColor, brand?: Brand): PlateCardDesignModel {
    const designCode = productCategory.products[0].id;
    const productSize = productCategory.products[0].size;
    const frontCaptionPosition = productCategory.products[0].caption;
    const frontMessageColors = productCategory.products[0].messageColors;
    if (productCategory.products[0].variants !== undefined) {
      const textColor = productCategory.products[0].variants[0].textColor;
      return {
        brand: brand,
        plateColor: plateColor,
        plate: {
          plateDesignCode: designCode,
          plateSize: productSize,
          textColor: textColor,
          captionPositon: frontCaptionPosition,
          captionMaxLength: productCategory.products[0].captionMaxLength,
          bottomCaptionMaxLength: productCategory.products[0].bottomCaptionMaxLength,
          topMessageColor: (frontMessageColors !== undefined && frontMessageColors.length > 0) ? frontMessageColors[0] : undefined,
          bottomMessageColor: (frontMessageColors !== undefined && frontMessageColors.length > 0) ? frontMessageColors[0] : undefined,
          topMessageText: frontCaptionPosition !== undefined ? "CUSTOM MESSAGE" : undefined,
          bottomMessageText: frontCaptionPosition !== undefined ? "CUSTOM MESSAGE" : undefined
        }
      }
    }
    throw new Error(`${productCategory.products[0].name}: Invalid Product model: the product missing variant/text color.`)
  }
  buildBasketPlateDesignProps(basketFrontPlate: BasketPlateResponseModel, basketBackPlate: BasketPlateResponseModel, topCaption?: BasketPlateCaptionResponseModel, bottomCaption?: BasketPlateCaptionResponseModel): CurrentPlateDesign | undefined {
    const productCategory = this.getPlateCategoryByPlateDesignCode(basketFrontPlate.designId, basketBackPlate.designId);
    if (productCategory) {
      const brand = productCategory.brand;
      const plateColor = productCategory.plateColor;
      const plates = productCategory.getProductModelsByDesignCodeAndForegroundColour(basketFrontPlate.designId, basketBackPlate.designId, basketFrontPlate.foregroundColorName, basketBackPlate.foregroundColorName);
      if (!plates) {
        throw new Error("Failed to load Plate Model. Please check your plate design and try again.")
      }

      let frontTopMessageColor = plates.front.messageColors ? topCaption ? plates.front.messageColors.find(c => c.id === topCaption.captionColourId) : plates.front.messageColors[0] : undefined;
      let frontTopMessageText = topCaption ? topCaption.captionText : ''
      let frontBottomMessageColor = plates.front.messageColors ? bottomCaption ? plates.front.messageColors.find(c => c.id === bottomCaption.captionColourId) : plates.front.messageColors[0] : undefined;
      let frontBottomMessageText = bottomCaption ? bottomCaption.captionText : '';
      let backTopMessageColor = plates.back.messageColors ? topCaption ? plates.back.messageColors.find(c => c.id === topCaption.captionColourId) : plates.back.messageColors[0] : undefined;
      let backTopMessageText = topCaption ? topCaption.captionText : '';
      let backBottomMessageColor = plates.back.messageColors ? bottomCaption ? plates.back.messageColors.find(c => c.id === bottomCaption.captionColourId) : plates.back.messageColors[0] : undefined;
      let backBottomMessageText = bottomCaption ? bottomCaption.captionText : '';

      const frontPlate: PlateDesignModel = {
        plateDesignCode: basketFrontPlate.designId,
        plateSize: plates.front.plateSize,
        textColor: plates.front.textColor,
        captionPositon: plates.front.captionPositon,
        topMessageColor: frontTopMessageColor,
        bottomMessageColor: frontBottomMessageColor,
        topMessageText: frontTopMessageText,
        bottomMessageText: frontBottomMessageText,
        disableDualSizing: plates.front.disableDualSizing
      };
      const backPlate: PlateDesignModel = {
        plateDesignCode: basketBackPlate.designId,
        plateSize: plates.back.plateSize,
        textColor: plates.back.textColor,
        captionPositon: plates.back.captionPositon,
        topMessageColor: backTopMessageColor,
        bottomMessageColor: backBottomMessageColor,
        topMessageText: backTopMessageText,
        bottomMessageText: backBottomMessageText,
        disableDualSizing: plates.back.disableDualSizing
      }
      const result: CurrentPlateDesign = {
        brand: brand,
        plateColor: plateColor,
        frontPlate: frontPlate,
        backPlate: backPlate
      };
      return result;
    }
  }
  buildDefaultPlateDesignProps(): CurrentPlateDesign {
    let plateColor: PlateColor;
    let productCategory: ProductModelCategory;
    let brand: Brand | undefined = undefined;
    if (this.brands !== undefined && this.brands.length > 0) {
      brand = this.brands[0];
    }

    if (this.plateColors.length > 0) {
      if (brand !== undefined) {
        const brandedCategories = this.getPlateCategoriesByBrand(brand);
        productCategory = brandedCategories[0];
        plateColor = brandedCategories[0].plateColor
      } else {
        plateColor = this.plateColors[0]
        productCategory = this.getPlateCategoryByColor(plateColor.name);
      }
      if (productCategory !== undefined) {
        return this.buildPlateDesignModel(productCategory, plateColor, brand);
      }
    }
    throw new Error("Invalid Product Model, unable to return the default plate design.")
  }
  buildDefaultCardDesignModel(): PlateCardDesignModel {
    let plateColor: PlateColor;
    let productCategory: ProductModelCategory;
    let brand: Brand | undefined = undefined;
    if (this.brands !== undefined && this.brands.length > 0) {
      brand = this.brands[0];
    }
    //console.log("buildDefaultCardDesignModel productCategory", this.plateColors)
    if (this.plateColors.length > 0) {
      if (brand !== undefined) {
        const brandedCategories = this.getPlateCategoriesByBrand(brand);
        productCategory = brandedCategories[0];
        plateColor = brandedCategories[0].plateColor
      } else {
        plateColor = this.plateColors[0]
        productCategory = this.getPlateCategoryByColor(plateColor.name);
      }
      if (productCategory !== undefined) {
        
        return this.buildPlateCardDesignModel(productCategory, plateColor, brand);
      }
    }
    throw new Error("Invalid Product Model, unable to return the default plate design.")
  }
  buildPlateDesignModelByPlateColorAndBrand(currentPlateDesign: CurrentPlateDesign, plateColorName: string, brand?: Brand): CurrentPlateDesign {
    const plateColor = this.plateColors.find(pc => pc.name === plateColorName)
    if (plateColor === undefined) {
      throw new Error("Plate Color " + JSON.stringify(plateColor) + " is not found.");
    }
    let productCategory: ProductModelCategory | undefined = undefined;
    if (brand !== undefined) {
      const brandedCategories = this.getPlateCategoriesByBrand(brand);
      productCategory = brandedCategories.find(bc => bc.plateColor.name === plateColorName)
    } else {
      productCategory = this.getPlateCategoryByColor(plateColorName);
    }
    if (productCategory !== undefined) {
      return this.internalBuildPlateDesignModel(currentPlateDesign, productCategory, plateColor, brand)
    }
    throw new Error("Failed to load the Product Design for the selected criteria.");
  }
  internalBuildPlateDesignModel(currentPlateDesign: CurrentPlateDesign, productCategory: ProductModelCategory, plateColor: PlateColor, brand?: Brand): CurrentPlateDesign {
    let frontPlateDesign: ProductModel;
    let backPlateDesign: ProductModel;
    if (currentPlateDesign.frontPlate.captionPositon !== undefined) {
      frontPlateDesign = productCategory.products.find(size => size.caption?.value === currentPlateDesign.frontPlate.captionPositon?.value) ?? productCategory.products[0];
      backPlateDesign = productCategory.products.find(size => size.caption?.value === currentPlateDesign.backPlate.captionPositon?.value) ?? productCategory.products[0];
    }
    else {
      frontPlateDesign = productCategory.products.find(size => size.size.id === currentPlateDesign.frontPlate.plateSize.id) ?? productCategory.products[0];
      backPlateDesign = productCategory.products.find(size => size.size.id === currentPlateDesign.backPlate.plateSize.id) ?? productCategory.products[0];
    }

    const frontCaptionPosition = productCategory.products[0].caption;
    const captionMaxLength = productCategory.products[0].captionMaxLength;
    const bottomCaptionMaxLength = productCategory.products[0].bottomCaptionMaxLength;
    const frontMessageColors = productCategory.products[0].messageColors;
    if (frontPlateDesign.variants !== undefined && backPlateDesign.variants !== undefined) {
      const frontTextColor = frontPlateDesign.variants?.find(textColor => textColor.textColor.id === currentPlateDesign.frontPlate.textColor.id) ?? frontPlateDesign.variants[0];
      const backTextColor = backPlateDesign.variants?.find(textColor => textColor.textColor.id === currentPlateDesign.backPlate.textColor.id) ?? backPlateDesign.variants[0];

      return {
        plateColor: plateColor,
        brand: brand,
        frontPlate: {
          plateDesignCode: frontPlateDesign.id,
          plateSize: frontPlateDesign.size,
          textColor: frontTextColor.textColor,
          captionPositon: frontCaptionPosition,
          captionMaxLength: captionMaxLength,
          bottomCaptionMaxLength: bottomCaptionMaxLength,
          topMessageColor: (frontMessageColors !== undefined && frontMessageColors.length > 0) ? frontMessageColors[0] : undefined,
          bottomMessageColor: (frontMessageColors !== undefined && frontMessageColors.length > 0) ? frontMessageColors[0] : undefined,
        },
        backPlate: {
          plateDesignCode: backPlateDesign.id,
          plateSize: backPlateDesign.size,
          textColor: backTextColor.textColor,
          captionPositon: frontCaptionPosition,
          captionMaxLength: captionMaxLength,
          bottomCaptionMaxLength: bottomCaptionMaxLength,
          topMessageColor: (frontMessageColors !== undefined && frontMessageColors.length > 0) ? frontMessageColors[0] : undefined,
          bottomMessageColor: (frontMessageColors !== undefined && frontMessageColors.length > 0) ? frontMessageColors[0] : undefined,
        }
      }
    }
    throw new Error("Invalid plateColorName (can't find the plate color), unable to return the plate design model.");
  }
  internalBuildPlateCardDesignModel(productCategory: ProductModelCategory, plateColor: PlateColor): PlateCardDesignModel {
    const defaultProduct = productCategory.products[0];
    const designCode = defaultProduct.id;
    const productSize = defaultProduct.size;
    const frontCaptionPosition = defaultProduct.caption;
    const frontMessageColors = defaultProduct.messageColors;
    if (defaultProduct.variants !== undefined) {
      const textColor = defaultProduct.variants[0].textColor;
      return {
        plateColor: plateColor,
        plate: {
          plateDesignCode: designCode,
          plateSize: productSize,
          textColor: textColor,
          captionPositon: frontCaptionPosition,
          topMessageColor: (frontMessageColors !== undefined && frontMessageColors.length > 0) ? frontMessageColors[0] : undefined,
          bottomMessageColor: (frontMessageColors !== undefined && frontMessageColors.length > 0) ? frontMessageColors[0] : undefined,
          topMessageText: frontCaptionPosition !== undefined ? "CUSTOM MESSAGE" : undefined,
          bottomMessageText: frontCaptionPosition !== undefined ? "CUSTOM MESSAGE" : undefined
        }
      }
    }
    throw new Error("Product Model " + defaultProduct.name + " definition is incorrect. Please review the kentico product definition.");
  }
  buildPlateCardDesignModelByPlateColor(plateColorName: string): PlateCardDesignModel {
    const plateColor = this.plateColors.find(pc => pc.name === plateColorName)
    if (plateColor === undefined) {
      throw new Error("Plate Color " + JSON.stringify(plateColor) + " is not found.");
    }
    const productCategory = this.getPlateCategoryByColor(plateColorName);
    return this.internalBuildPlateCardDesignModel(productCategory, plateColor)
  }
  //NEED TO BE REVIEWED AFTER ADD MESSAGE PLATE AND EURO BRAND
  buildPlateDesignPropsByPlateColorAndTextColor(plateColor: PlateColor, textColorId: number, brand?: Brand, position?: string, frontPlate?: PlateDesignModel, backPlate?: PlateDesignModel,): CurrentPlateDesign {
    let productCategory = undefined;
    if (brand !== undefined) {
      const brandedCategories = this.getPlateCategoriesByBrand(brand);
      productCategory = brandedCategories.find(bc => bc.plateColor.name === plateColor.name)
    } else {
      productCategory = this.getPlateCategoryByColor(plateColor.name);
    }
    if (productCategory !== undefined) {
      let matchedProduct: ProductModel | undefined;
      if (position !== undefined) {
        for (let product of productCategory.products) {
          if (product.caption !== undefined && product.caption.value === position) {
            matchedProduct = product;
            break;
          }
        }
      }

      if (matchedProduct === undefined) {
        matchedProduct = productCategory.products[0];
      }
      const designCode = matchedProduct.id;
      const productSize = matchedProduct.size;
      const frontMessageColors = matchedProduct.messageColors;
      const frontCaptionPosition = matchedProduct.caption;
      if (matchedProduct.variants !== undefined) {
        const variant = matchedProduct.variants.find(v => v.textColor.id === textColorId);
        let textColor
        if (variant !== undefined) {
          textColor = variant.textColor;
        } else {
          textColor = matchedProduct.variants[0].textColor
        }
        if (frontPlate !== undefined && backPlate !== undefined) {
          frontPlate.textColor = textColor;
          backPlate.textColor = textColor;
        }
        return {
          plateColor: plateColor,
          brand: brand,
          frontPlate: frontPlate !== undefined ? frontPlate : {
            plateDesignCode: designCode,
            plateSize: productSize,
            textColor: textColor,
            captionPositon: frontCaptionPosition,
            topMessageColor: (frontMessageColors !== undefined && frontMessageColors.length > 0) ? frontMessageColors[0] : undefined,
            bottomMessageColor: (frontMessageColors !== undefined && frontMessageColors.length > 0) ? frontMessageColors[0] : undefined,
          },
          backPlate: backPlate !== undefined ? backPlate : {
            plateDesignCode: designCode,
            plateSize: productSize,
            textColor: textColor,
            captionPositon: frontCaptionPosition,
            topMessageColor: (frontMessageColors !== undefined && frontMessageColors.length > 0) ? frontMessageColors[0] : undefined,
            bottomMessageColor: (frontMessageColors !== undefined && frontMessageColors.length > 0) ? frontMessageColors[0] : undefined,
          }
        }
      }
    }
    throw new Error("Invalid plate color and text color selected, unable to return the default plate design.")
  }
  buildPlateModelForPlateSizeChange(frontPlate: PlateModel, backPlate: PlateModel, frontPlateSizeId: number): PlateModel {
    const productModel = this.getCurrentProductModel(frontPlate.plateDesignCode, backPlate.plateDesignCode);
    var model = productModel.getAllPlateModels().find(pm => pm.textColor.id === frontPlate.textColor.id && pm.plateSize.id === frontPlateSizeId);
    if (model === undefined) {
      throw new Error("Unable to located the Plate design for the selected size")
    }
    return model;
  }
}