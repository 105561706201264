import {
    getKenticoCode, getPlateSize, getPlateColor, getTextColor, getMessageColors, getCaptionPosition, getPlateFormat, getVehicleType, getDealerOnly, getAuthorizedDealers
} from '@utils/Helpers'

import {
    PlateColor, ProductVariant, ProductModel, ProductModelCategory, ProductModelCategoryList, Brand, PlateCategoryMetaInfo, VehicleType, VehicleTypesSetting
} from "@dataModels/ProductModels"

import { KenticoChoice } from '@utils/KontentTypes'
import { PlateDesignFormats } from '@utils/Constant'
import { PlateSizeGuideModel } from '@dataModels/ProductModels/PlateHoleGuideModel'

type MappedProductDesignModel = {
    designName: string
    disableDualSizing: boolean
    transactionTypes: KenticoChoice[]
    vehicleType: KenticoChoice
    plateDesignCode: KenticoChoice
    plateTextColor: KenticoChoice
    plateSize: KenticoChoice
    plateColor: KenticoChoice
    basePrice: number
    redesignPrice: number
    upgradePrice: number
    caption?: KenticoChoice
    captionMaxLength?: number,
    messageColors?: KenticoChoice[]
    brand?: Brand
    dualPlate?: MappedProductDesignModel
    dealerOnly: KenticoChoice
    authorizedDealers?: KenticoAuthorizedDealers,
    bottomCaptionMaxLength?: number,
}

export type KenticoAuthorizedDealers = {
    title: {
        value: string
    },
    link_message: {
        value: string
    },
    description: {
        value: string
    },
    dealer_contacts: {
        linked_items: {
            elements: {
                dealer_name: {
                    value: string
                },
                contact_name: {
                    value: string
                }
                phone: {
                    value: string
                }
                formatted_phone_number: {
                    value: string
                },
                dealer_id: {
                    value: string
                }
            }
        }[]
    }
}

export const mapToProductElements = (productElement: any) => {
    const {
        name,
        dealerOnlyDesign,
        authorizedDealers,
        transactionTypes,
        vehicleTypes,
        kpiPlateDesignCode,
        kpiPlateForegroundColor,
        plateSize,
        plateColor,
        basePrice,
        redesignPrice,
        govtUpgradePrice,
        captions,
        maxMessageLengthMm,
        messageColors,
        europeanBrandName,
        dualSizePlate,
        bottomCaptionMaxLengthMm
    } = productElement;

    const result: MappedProductDesignModel = {
        designName: name.value,
        transactionTypes: transactionTypes.value,
        vehicleType: vehicleTypes.value[0],
        disableDualSizing: productElement.disableDualSizing.value.length > 0 ? true : false,
        plateDesignCode: kpiPlateDesignCode.value[0],
        plateTextColor: kpiPlateForegroundColor.value[0],
        plateSize: plateSize.value[0],
        plateColor: plateColor.value[0],
        basePrice: basePrice.value,
        redesignPrice: redesignPrice.value,
        upgradePrice: govtUpgradePrice.value,
        caption: captions.value.length > 0 ? captions.value[0] : undefined,
        captionMaxLength: maxMessageLengthMm.value,
        bottomCaptionMaxLength: bottomCaptionMaxLengthMm?.value || undefined,
        messageColors: messageColors.value.length > 0 ? messageColors.value : undefined,
        brand: europeanBrandName.value[0].codename === 'n_a'
            ? undefined
            : {
                  name: europeanBrandName.value[0].name,
                  value: europeanBrandName.value[0].codename
              },
        dualPlate:
            dualSizePlate !== undefined && dualSizePlate.linkedItems.length > 0
                ? mapToProductElements(dualSizePlate.linkedItems[0].elements)
                : undefined,
        dealerOnly: dealerOnlyDesign.value[0],
        authorizedDealers:
            authorizedDealers.linkedItems.length > 0
                ? authorizedDealers.linkedItems[0].elements
                : undefined
    };

    return result;
};

export const mapToProductModelCategory = (item: MappedProductDesignModel): ProductModelCategory => {
    const plateColor: PlateColor = getPlateColor(item.plateColor.name, item.plateColor.codename);
    let productVariant: ProductVariant = {
        textColor: getTextColor(item.plateTextColor),
        dualProduct: item.dualPlate === undefined ? undefined : new ProductModel(
            item.dualPlate.designName,
            +getKenticoCode(item.dualPlate.plateDesignCode.codename),
            getPlateSize(item.dualPlate.plateSize.name, item.dualPlate.plateSize.codename),
            undefined,
            item.dualPlate.caption === undefined ? undefined : getCaptionPosition(item.dualPlate.caption),
            item.captionMaxLength,
            item.dualPlate.messageColors === undefined ? undefined : getMessageColors(item.dualPlate.messageColors),
            getVehicleType(item.dualPlate.vehicleType),
            getDealerOnly(item.dualPlate.dealerOnly),
            item.authorizedDealers === undefined ? undefined : getAuthorizedDealers(item.authorizedDealers),
            item.disableDualSizing,
            item.bottomCaptionMaxLength
        )
    }
    const productModel: ProductModel = new ProductModel(
        item.designName,
        +getKenticoCode(item.plateDesignCode.codename),
        getPlateSize(item.plateSize.name, item.plateSize.codename),
        [productVariant],
        item.caption === undefined ? undefined : getCaptionPosition(item.caption),
        item.captionMaxLength,
        item.messageColors === undefined ? undefined : getMessageColors(item.messageColors),
        getVehicleType(item.vehicleType),
        getDealerOnly(item.dealerOnly),
        item.authorizedDealers === undefined ? undefined : getAuthorizedDealers(item.authorizedDealers),
        item.disableDualSizing,
        item.bottomCaptionMaxLength
    )
    const productModelCategory = new ProductModelCategory(plateColor, [productModel], item.brand);
    return productModelCategory;
}

type KenticoProductDesignsCategories = Array<{
    elements: object
}>

export const mapToProductModelCategoryList = (name: string, categories: KenticoProductDesignsCategories, vehicleType: VehicleType): ProductModelCategoryList => {
    const categoryList = categories.map(c => mapToProductElements(c.elements));

    let categoryModels: ProductModelCategory[] = [];
    categoryList.forEach(cl => {
        const vt = getVehicleType(cl.vehicleType);
        if (vt.name === vehicleType.name) {
            categoryModels.push(mapToProductModelCategory(cl));
        }
    });
    let result: ProductModelCategoryList = new ProductModelCategoryList(name, [], []);
    categoryModels.forEach(element => {
        result.add(element);
    });
    return result;
}

const categoryAvailableForVehicleType = (element: any, vehicleType: VehicleType) => {
    if (element.vehicleTypes.value !== undefined && element.vehicleTypes.value.length > 0) {
        const availableVehicleTypes = element.vehicleTypes.value;
        const isAvailable = availableVehicleTypes.find((vt: KenticoChoice) => vt.codename.toUpperCase() === vehicleType.name.toUpperCase()) !== undefined;
        return isAvailable;
    }
    return false;
}

export const mapToPlateDesignList = (elements: Array<any>, combination: string, vehicleType?: VehicleType) => {
    const vt = vehicleType === undefined ? VehicleTypesSetting.Car : vehicleType;
    let result: { metaInfo: PlateCategoryMetaInfo, products: ProductModelCategoryList }[] = [];
    elements.forEach((element) => {
        const isAvailable = categoryAvailableForVehicleType(element.elements, vt);
        if (isAvailable) {
            const metaInfo: PlateCategoryMetaInfo = calculatePlateMetaInfo(element.elements, combination);
            const products = mapToProductModelCategoryList(metaInfo.name, element.elements.productDesigns.linkedItems, vt);
            result.push({
                metaInfo: metaInfo,
                products: products
            });
        }
    })
    return result;
}

export const mapToPlateDesignGroupList = (els: Array<any>, combination: string, vehicleType?: VehicleType) => {
    let results: Array<any> = [];
   
    els.forEach((el) => {
        const elements: Array<any> = el.elements.groupName.linkedItems;
        const gName = el.elements.groupNameToDisplay.value;
        let designCounts = 0;
        let groupeAvailable = false;

        const vt = vehicleType === undefined ? VehicleTypesSetting.Car : vehicleType;
        let result: { metaInfo: PlateCategoryMetaInfo, products: ProductModelCategoryList }[] = [];
        elements.forEach((element) => {
            const isAvailable = categoryAvailableForVehicleType(element.elements, vt);
            if (isAvailable) {
                groupeAvailable = true;
                const metaInfo: PlateCategoryMetaInfo = calculatePlateMetaInfo(element.elements, combination);
                const products = mapToProductModelCategoryList(metaInfo.name, element.elements.productDesigns.linkedItems, vt);
                result.push({
                    metaInfo: metaInfo,
                    products: products,
                });
                designCounts++;
            }
        });
        if (groupeAvailable) {
            const data: any = {
                groupName: gName,
                items: result,
                designCounts: designCounts
            };
            results.push(data);
        }
    });
    return results;
};

export const mapToPlateDesignListMobile = (elements: Array<any>, combination: string, vehicleType?: VehicleType) => {
    const vt = vehicleType === undefined ? VehicleTypesSetting.Car : vehicleType;
    let result: { metaInfo: PlateCategoryMetaInfo, products: ProductModelCategoryList, vehicleTpes: KenticoChoice[], plateSizes: KenticoChoice[] }[] = [];
    let designList: {}[] = [];
    elements.forEach((element) => {
        element.elements.groupName.linkedItems.forEach((element: { elements: {} }) => {
            designList.push(element);
        });
    });
    designList.forEach((element) => {
        const isAvailable = categoryAvailableForVehicleType(element.elements, vt);
        if (isAvailable) {
            const metaInfo: PlateCategoryMetaInfo = calculatePlateMetaInfo(element.elements, combination);
            const products = mapToProductModelCategoryList(metaInfo.name, element.elements.productDesigns.linkedItems, vt);
            const vehicleTpes = element.elements.vehicleTypes.value;
            const plateSizes = element.elements.plateSizes.value;
            result.push({
                metaInfo: metaInfo,
                products: products,
                vehicleTpes: vehicleTpes,
                plateSizes: plateSizes
            });
        }
    });
    return result;
};

export const calculatePlateMetaInfo = (element: any, formattedCombination: string): PlateCategoryMetaInfo => {
    const startingPrice = calculatePlateDesignStartingPrice(element, formattedCombination);

    const lockedMessageColor = element.lockCaptionColors && element.lockCaptionColors.value.length > 0 && element.lockCaptionColors.value[0].codename === "true";
    return {
        startingPrice: startingPrice,
        name: element.categoryName.value,
        description: element.categoryDescriptionRich.value,
        featureImage: element.featureImage?.value[0]?.url || '',
        isNewDesign: element.newDesign.value[0].name,
        url: element.url ? element.url.value : '',
        featureImageMobile: element.featureImageMobile?.value[0]?.url || '',
        lockedMessageColor: lockedMessageColor
    };
};

export const calculatePlateDesignStartingPrice = (element: any, formattedCombination: string) => {
    const combination = formattedCombination.toString().split(' ').join('');
    const plateFormat = getPlateFormat(combination);
    let startingPrice = 0;
    if (plateFormat === PlateDesignFormats.Fixed) {
        startingPrice = element.fixedFomatPrice.value;
    } else if (plateFormat === PlateDesignFormats.Flexi) {
        startingPrice = element.flexiFormatPrice.value;
    } else if (plateFormat === PlateDesignFormats.Premium3) {
        startingPrice = element.premium3CharacterPrice.value;
    } else {
        startingPrice = element.premium2CharacterPrice.value;
    }
    const upgrade = element.upgradeStartingPrice.value;
    const redesign = element.redesignStartingPrice.value;
    return {
        new: startingPrice,
        upgrade: upgrade,
        redesign: redesign
    };
};

export const mapToPlateHoleGuidePlates = (kenticoPlates: any): PlateSizeGuideModel[] => {
    let plateModels: PlateSizeGuideModel[] = [];

    kenticoPlates.forEach((kenticoPlate: any) => {
        plateModels.push({
            descripton: kenticoPlate.elements.description.value,
            plate_image_url: kenticoPlate.elements.plateImage.value[0].url,
            size_height: kenticoPlate.elements.sizeHeight.value,
            size_width: kenticoPlate.elements.sizeWidth.value,
            title: kenticoPlate.elements.title.value
        })
    });

    return plateModels;
}